<template>
  <div class="h-100">
    <b-tabs
      content-class="mt-3"
      pills
      align="center"
      class="d-flex flex-column h-100"
      nav-wrapper-class="nav-tabs-category"
    >
      <b-tab
        v-for="(category, index) in categories"
        :key="index"
        :title="category.name"
        title-item-class="px-5"
      >
        <skeleton-groups-filter
          v-if="groupsLoaded && skeletonGroupsPublic.length && index === 0"
          v-model="filterGroups"
          class="mb-3"
          :placeholder="$t('player.playerTagLabel')"
          left-icon="tags"
          :options="skeletonGroupsPublic"
          :selectedTags="selectedGroups"
        ></skeleton-groups-filter>
        <b-row class="px-2 skeletons-list">
          <!-- Create & Edit private Playlist -->
          <div v-if="groupsLoaded && index === 1" class="px-2 mb-3 w-100">
            <h-button
              v-if="hasCreateSkeletonRight"
              @click="createPlaylist"
              blue
              outline
              class="w-100"
              :disabled="
                editPlaylistMode &&
                  currentSkeleton &&
                  currentSkeleton.idSkeleton < 0
              "
            >
              <b-icon icon="plus" class="mr-2"></b-icon>
              {{ $t('planification.createPlaylist') }}
            </h-button>
          </div>
          <template
            v-if="
              skeletonLoaded &&
                category.skeletons != null &&
                category.skeletons.length > 0
            "
          >
            <b-col
              v-for="(skeleton, indexske) in category.skeletons"
              :key="indexske"
              lg="6"
              md="2"
              sm="2"
              cols="3"
              class="px-2"
            >
              <MusiqueDragItem
                v-if="hasClientProgram"
                :img-id="skeleton.idImageCover"
                :data="dataEvent(skeleton)"
                :canEditDelete="index === 1"
                :canDrag="hasClientProgram"
              />
            </b-col>
            <b-col
              v-if="index === 1 && hasCreateSkeletonRight"
              lg="6"
              md="2"
              sm="2"
              cols="3"
              class="px-2"
            >
              <MusiqueDragItemAddPlaylist />
            </b-col>
          </template>
          <template v-else-if="!category.skeletons">
            <b-col
              v-for="(skeleton, index) in 12"
              :key="index"
              lg="6"
              md="6"
              sm="2"
              cols="3"
              class="px-2 mb-3"
            >
              <MusiqueDragItemSkeleton />
            </b-col>
          </template>
          <template
            v-else-if="
              skeletonLoaded &&
                category.skeletons &&
                category.skeletons.length === 0
            "
            class="d-flex flex-column align-items-center justify-content-center"
          >
            <b-col
              v-if="index === 1 && hasCreateSkeletonRight"
              lg="6"
              md="2"
              sm="2"
              cols="3"
              class="px-2"
            >
              <MusiqueDragItemAddPlaylist />
            </b-col>
            <div
              v-else
              class="d-flex flex-column align-items-center justify-content-center"
            >
              <img
                src="@/assets/svg/empty-pad.svg"
                alt="empty pad"
                width="80%"
              />
              <span class="text-center h5 mt-5">
                {{ $t('planification.noProg') }}
              </span>
            </div>
          </template>
        </b-row>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import MusiqueDragItem from '@/components/musique/MusiqueDragItem'
import MusiqueDragItemSkeleton from '@/components/skeleton/MusiqueDragItemSkeleton'
import MusiqueDragItemAddPlaylist from '@/components/musique/MusiqueDragItemAddPlaylist'
import SkeletonGroupsFilter from '@/components/musique/SkeletonGroupsFilter'

import { EventBus } from '@/components/base/event-bus.js'
export default {
  components: {
    MusiqueDragItem,
    MusiqueDragItemSkeleton,
    SkeletonGroupsFilter,
    MusiqueDragItemAddPlaylist,
  },
  data() {
    return {
      filterGroups: [],
      selectedGroups: [],
    }
  },
  methods: {
    dataEvent(skeleton) {
      let obj = {
        id: skeleton.idSkeleton,
        imgSkeleton: skeleton.idImageCover,
        title: skeleton.name,
        duration: '06:00',
        backgroundColor: skeleton.color,
        isTracklist: skeleton.isTracklist,
      }
      return obj
    },
    createPlaylist() {
      this.$store.commit('musique/SET_EDIT_PLAYLIST_MODE', true)

      const skeleton = {
        idSkeleton: -1,
        idBrand: this.idBrand,
        name: this.$t('planification.newPlaylist'),
        skeletonType: 20,
        visibleInPlayList: true,
        flags: 10,
        idIdentity: this.idIdentity,
        skeletonLine: [],
        idImageCover: -1,
        idMusicLibrary: 1,
        visibility: 2,
      }
      this.$store.dispatch('musique/_createEditCurrentSkeleton', skeleton)
    },
  },
  computed: {
    publicSkeletons() {
      if (this.skeletons && !this.filterGroups.length)
        return this.skeletons.filter((skeleton) =>
          skeleton.alias.length > 0
            ? skeleton.aliasVisibility === 1
            : skeleton.visibility === 1
        )
      else if (this.skeletons && this.filterGroups.length) {
        let publicSkeletonsFiltered = []
        this.skeletons.forEach((elem) => {
          const skeletonTags = elem.skeletonTags.map((e) => e.idSkeletonTag)
          const hasFilterGroups = this.filterGroups.every((ai) =>
            skeletonTags.includes(ai)
          )
          if (
            hasFilterGroups &&
            (elem.alias.length > 0
              ? elem.aliasVisibility === 1
              : elem.visibility === 1)
          )
            publicSkeletonsFiltered.push(elem)
        })
        return publicSkeletonsFiltered
      } else return null
    },
    privateSkeletons() {
      if (this.skeletons)
        return this.skeletons.filter((skeleton) =>
          skeleton.alias.length > 0
            ? skeleton.aliasVisibility === 2
            : skeleton.visibility === 2
        )
      else return null
    },
    categories() {
      return [
        {
          name: this.$t('planification.ambiences'),
          skeletons: this.publicSkeletons,
        },
        {
          name: this.$t('planification.myPlaylists'),
          skeletons: this.privateSkeletons,
        },
      ]
    },
    skeletonGroupsPublic() {
      if (this.skeletonGroups)
        return this.skeletonGroups.filter((group) => !group.isPrivate)
      else return null
    },
    hasCreateSkeletonRight() {
      return this.$store.state.user?.user?.rights?.includes(
        'MYHUBCAST_MANAGE_PLAYLIST'
      )
    },
    hasClientProgram() {
      const clientStream = this.streams.find((e) => e.category === 1)
      return clientStream ? true : false
    },
    ...mapState({
      idBrand: (state) => state.user.user.visibleBrands[0].id,
      idIdentity: (state) => state.user.user.visibleIdentities[0].id,
      groupsLoaded: (state) => state.musique.groupsLoaded,
      skeletonGroups: (state) => state.musique.skeletonGroups,
      skeletons: (state) => state.musique.skeletons,
      streams: (state) => state.musique.streams,
      skeletonLoaded: (state) => state.musique.skeletonLoaded,
      editPlaylistMode: (state) => state.musique.editPlaylistMode,
      currentSkeleton: (state) => state.musique.currentSkeleton,
    }),
  },
  created() {
    this.$store.dispatch('musique/_getSkeletons')
  },
  mounted() {
    EventBus.$on('playlistCreateEditSuccess', (idSkeleton) => {
      if (idSkeleton) {
        this.$store.dispatch('musique/_getSkeletons')
        setTimeout(() => {
          this.$toast.success(
            `${this.$t('planification.successMessage1')} ${
              idSkeleton < 0
                ? this.$t('planification.created')
                : this.$t('planification.edited')
            } ${this.$t('planification.successMessage2')}`
          )
        }, 500)
      }
    })
  },
  beforeDestroy() {
    EventBus.$off('playlistCreateEditSuccess')
  },
}
</script>

<style lang="scss" scoped>
h4 {
  font-weight: 600;
}
.skeletons-list {
  overflow-y: auto;
}
</style>
