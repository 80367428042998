<template>
  <div
    v-if="data && data.id"
    v-bind:id="'dragItem' + data.id"
    class="drag-card w-100 h-100 mb-3"
    :class="{ 'can-drag': this.canDrag }"
  >
    <div class="img">
      <img
        v-if="imgId > 0"
        :src="
          (env === 'production' ? '/api' : 'http://localhost:5000/api') +
            `/skeleton/${imgId}/cover`
        "
        class="img w-100"
      />
      <img
        v-else
        src="@/assets/img/default-skeleton-cover.png"
        class="img w-100"
      />
      <div
        v-if="imgId === null || imgId < 0"
        class="skeleton-title text-center text-secondary font-weight-bold"
      >
        <div class="text-center px-2">{{ data.title }}</div>
      </div>
      <div class="img-hover">
        <b-icon
          v-if="hasSchedulingRight"
          icon="arrows-move"
          font-scale="2"
        ></b-icon>
      </div>

      <!-- <b-icon
        @click="playSkeleton(data.id)"
        icon="play-circle-fill"
        class="play-skeleton"
        font-scale="1.5"
      ></b-icon> -->
      <div v-if="canEditDelete && hasCreateSkeletonRight" class="edit-delete">
        <button
          :class="{ 'w-100': !data.isTracklist }"
          @click.stop="deleteSkeletonCheck"
        >
          <b-icon icon="trash"></b-icon>
        </button>
        <button v-if="data.isTracklist" @click.stop="editSkeleton">
          <b-icon icon="pencil"></b-icon>
        </button>
      </div>
    </div>
    <ModalConfirmSkeletonDelete
      v-if="data && showDeleteConfirm"
      :showDeleteConfirm="showDeleteConfirm"
      :skeletonName="data.title"
      :skeletonId="parseInt(data.id)"
      @close="showDeleteConfirm = false"
    />
  </div>
</template>

<script>
import { Draggable } from '@fullcalendar/interaction'
import musiqueService from '@/services/musiqueService'
import ModalConfirmSkeletonDelete from '@/components/modals/ModalConfirmSkeletonDelete'
import playerService from '@/services/playerService'

export default {
  components: {
    ModalConfirmSkeletonDelete,
  },
  data() {
    return {
      cover: null,
      loaded: false,
      showDeleteConfirm: false,
    }
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    imgId: {
      type: Number,
    },
    canEditDelete: {
      type: Boolean,
    },
    canDrag: {
      type: Boolean,
    },
  },
  methods: {
    deleteSkeletonCheck() {
      this.showDeleteConfirm = true
    },
    async editSkeleton() {
      this.$store.commit('musique/SET_EDIT_PLAYLIST_MODE', true)

      this.$store.commit('musique/SET_FETCH_PLAYLIST_EDIT', true)
      try {
        const skeleton = await musiqueService.getSkeleton(this.data.id)
        const skeletonLinesMap = skeleton.data.skeletonLine.map((e) => e.idFile)

        const skeletonLinesDetail = await musiqueService.getSkeletonLineEditMode(
          skeletonLinesMap
        )

        let skeletonLinesDetailFormat = skeletonLinesDetail.data.map((obj) => {
          let rObj = {}
          rObj.idSkeletonLine = skeleton.data.skeletonLine.find(
            (e) => e.idFile === obj.id
          ).idSkeletonLine
          rObj.idSkeleton = this.data.id //Create/Edit
          rObj.lineNumber = skeleton.data.skeletonLine.find(
            (e) => e.idFile === obj.id
          ).lineNumber
          rObj.title = obj.title
          rObj.idFile = obj.id
          rObj.artists = obj.performer.map((i) => i.name).join(', ')
          rObj.albumCover = this.albumCover(obj.id)
          rObj.isrc = obj.isrc
          rObj.foundStatus = 'Identifié'
          return rObj
        })

        //keep previous ordering
        skeletonLinesDetailFormat.sort((a, b) => a.lineNumber - b.lineNumber)

        skeleton.data.skeletonLine = skeletonLinesDetailFormat
        this.$store.dispatch(
          'musique/_createEditCurrentSkeleton',
          skeleton.data
        )
        this.$store.commit('musique/SET_FETCH_PLAYLIST_EDIT', false)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('skeleton error')
      }
    },
    albumCover(idImage) {
      if (idImage)
        return `https://fileapi.radioshop.net/getcover.aspx?idfile=${idImage}&ft=1201`
      else return null
    },
    playSkeleton(value) {
      const params = {
        IdSite: this.$store.state.user.selectedSite.id,
        IdStream: value,
        playbackType: 1,
        idSkeleton: value,
      }

      try {
        playerService.setChangeFlux(params)
        this.$toast.success('La playlist du player a été modifiée avec succès')
      } catch (e) {
        this.$toast.error(
          "Une erreur s'est produite, veuillez réessayer ultérieurement"
        )
      }
    },
  },
  computed: {
    env() {
      return process.env.NODE_ENV
    },
    hasSchedulingRight() {
      return (
        this.$store.state.user?.user?.rights?.includes(
          'MYHUBCAST_SCHEDULE_MUSIC_SEQUENCE'
        ) && this.canDrag
      )
    },
    hasCreateSkeletonRight() {
      return (
        this.$store.state.user?.user?.rights?.includes(
          'MYHUBCAST_MANAGE_PLAYLIST'
        ) && this.canDrag
      )
    },
  },
  async mounted() {
    if (this.hasSchedulingRight) {
      let draggable = document.getElementById('dragItem' + this.data.id)
      new Draggable(draggable, {
        eventData: this.data,
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.drag-card {
  .img {
    position: relative;
    max-width: 200px;
  }
  img {
    transition: 0.3s;
    border-radius: 10px;
    max-width: 200px;
    box-shadow: 0 4px 5px 0 $h-box-shadow;
  }
  h5 {
    font-size: 15px;
    font-weight: 600;
  }
  .img-hover {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    transition: 0.3s;
    opacity: 0;
  }

  &.can-drag:hover {
    cursor: pointer;
    img {
      filter: brightness(0.35);
      box-shadow: 0 4px 12px 0 $h-box-shadow;
    }
    .img-hover {
      opacity: 1;
    }
  }
  .skeleton-title {
    display: flex;
    height: 100%;
    max-width: 200px;
    width: 100%;
    position: absolute;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
  }
  &:focus {
    .skeleton-title {
      opacity: 0;
    }
  }
  .play-skeleton {
    position: absolute;
    display: none;
    top: 7px;
    right: 7px;
    z-index: 2;
    color: white;
    &:hover {
      opacity: 0.85;
    }
  }
  .edit-delete {
    position: absolute;
    transition: 0.3s;
    overflow: hidden;
    display: none;
    height: 45px;
    width: 100%;
    bottom: 0;
    border-radius: 0 0 10px 10px;
    border-top: 1px solid white;
    button {
      background: none;
      color: white;
      width: 50%;
      transition: 0.1s;
      border: 0;
      box-shadow: none;
      &:first-child {
        border-radius: 0 0 0 10px;
        border-right: 1px solid white;
      }
      &:last-child {
        border-radius: 0 0 10px 0;
      }
      &:hover {
        background: white;
        color: $h-main-text;
      }
      &.w-100 {
        width: 100%;
      }
    }
  }
  &:hover {
    .edit-delete {
      display: flex;
    }
    .play-skeleton {
      display: block;
    }
  }
}
</style>
